@import '../../../../../styles/variables-mixins.sass';
@import '../../../../../styles/apple-download.sass';

.cta-game-item {
    .game-list {
        padding: 0;
        list-style-type: none;
        text-align: center;
        .game {
            @include respond-max-width(md) {
                margin: 1em auto 2em; }
            @include respond-handheld-max-width(sm, landscape) {
                width: 25%; }
            .image-section {
                text-align: center;
                .image {
                    width: 125px;
                    height: 125px;
                    margin: auto;
                    border-radius: 23px;
                    @include respond-max-width(xs) {
                        width: 100%;
                        max-width: 125px;
                        height: auto; } } }

            .game-title-section {
                padding: 1em 0;
                text-align: center;
                @include respond-max-width(xs) {
                    padding: 0.5em 0; }
                .game-title {
                    font-family: $robotoCondensed;
                    font-weight: 700;
                    font-size: 1em;
                    color: $gold-text; } }
            .download-section {
                .apple-download {
                    width: 100%;
                    max-width: 160px;
                    height: 56px;
                    @include apple-download-icon--black;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin: auto; } } } } }
