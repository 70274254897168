@import '../../../../../styles/variables-mixins.sass';

#footerSection {
    text-size-adjust: 100%;
    @include respond-handheld-max-width(sm, landscape) {
        font-size: 8px; }

    #egf {
       .accent-color-hover,
       .text-color.accent-color-hover {
            &:hover {
                color: $gold-text; } } } }

