@import '../../../../../styles/variables-mixins.sass';

.features {
    .title-section {
        .title {
            @include title-font-base;
            color: $white;
            width: auto;
            @include respond-max-width(xs) {
                font-size: 1.75em;
                padding: 0.5em 0;
                margin: 0.5em 0;
                text-align: center;
                width: 100%;
                background: $black; }
            .highlight {
                background: $black;
                padding: 0.1em 0.5em 0.1em 1.5em;
                @include respond-max-width(xs) {
                    background: none;
                    padding: 0; } } } }

    .highlighted-feature {
        .bg-container {
            background-size: 200% auto;
            background-repeat: no-repeat; }
        .display-container {
            .title-section {
                .title {
                    @include respond-max-width(xs) {
                        width: 100%;
                        text-align: center;
                        font-size: 1em;
                        padding: 1em 0;
                        .higlight {
                            padding: 0; } } } }

            .description-section {
                    @include respond-max-width(xs) {
                        margin: 1em 0 2em;
                        .description {
                            text-align: center; } } } } } }

