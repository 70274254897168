@import '../../../../../styles/variables-mixins.sass';

.bg-wrapper {
    background: #333538;
    padding: 3em 0; }
.bg-wrapper {}
.video-gallery {
    .title {
        @include title-font-base;
        color: $black;
        background: $white;
        padding: 0.1em 0.5em 0.1em 1.5em;
        display: inline-block;
        @include respond-max-width(xs) {
            font-size: 1.75em;
            padding: 0.5em 0;
            margin: 0.5em 0;
            text-align: center;
            width: 100%; } }

    .video-carousel {
        margin-top: 1em;
        .slick-prev,
        .slick-next {
            top: -4em;
            width: 2em;
            height: 2em;
            @include respond-max-width(xs) {
                top: -4.2em; }
            .slick-control {
                display: none; } }
        .slick-next {
            right: 0; }

        .slick-prev {
            left: unset;
            right: 1.7em; }
        .slick-prev:before,
        .slick-next:before {
            background: $gameInfoCarouselArrowColor;
            padding: 5px; }
        .slick-prev:before {
            @include previous-arrow; }
        .slick-next:before {
            @include next-arrow; }
        .video-dots {
            .dots {
                border-color: $light-gray;
                width: 10px;
                height: 10px; }
            .slick-active {
                .dots {
                  background: $gameInfoCarouselActiveDotColor;
                  border-color: $gameInfoCarouselActiveDotColor; } } }

        .video-container {
            padding: 0.5em;
            .youtube-video {
                iframe {
                    @include respond-max-width(xs) {
                        width: 95vw; } } } }

        .video-container.carousel-video {
            margin: 3.5em auto auto auto;
            overflow: hidden;
            width: 100%;
            .youtube-video {
                text-align: center; } }

        .video-embed-container {
            .video-title {
                @include font-base;
                text-transform: uppercase;
                .highlight {
                    padding: 0.1em 0.3em;
                    color: $white;
                    background: $black; } }
            .video {
                background: $gameInfoVideoSectionBgColor;
                box-shadow: 0px 12px 27px -13px rgba(0, 0, 0, 0.65);
                width: auto;
                height: 275px;
                margin: 3em 1em 0 0;
                padding: 5em;
                @include respond-min-width(lg) {
                    height: 400px; }
                .video-text {
                    text-align: center;
                    @include vertical-align; } } } } }
