@import '../../../../../styles/variables-mixins.sass';

.cta-modal {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $outerBoxBgColor;
    overflow: hidden;
    @include respond-max-width(xs) {
        background: $innerBoxBgColor; }
    .close-section {
        text-align: right;
        padding: 4em 4em 0;
        @include respond-max-width(md) {
            padding: 2em 2em 0; }
        @include respond-max-width(xs) {
            padding: 4em 2em 0; }
        .close-btn {
            border: none;
            background: none;
            &:focus {
                outline: none; } }
        .close-icon {
            font-size: 2.5em;
            @include respond-max-width(lg) {
                font-size: 2em; }
            color: $white; } }
    .box {
        background: $innerBoxBgColor;
        width: 80%;
        margin: 5em auto auto auto;
        color: $white;
        padding: 0.5em;
        @include respond-max-width(xs) {
            width: 100%;
            margin: 0;
            padding: 0;
            background: none; }
        .content-wrapper {
            padding: 4em;
            @include respond-max-width(md) {
                padding: 2em; }
            @include respond-max-width(xs) {
                padding: 0 1em; } } } }
