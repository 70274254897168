@import '../../../../../styles/variables-mixins.sass';

.additional-features {
    @include respond-min-width(md) {
        margin: 4em 0; }
    .title-section {
        .title {
            @include title-font-base;
            color: #fff;
            background: #000;
            padding: 0.1em 0.5em 0.1em 1.5em;
            width: 385px;
            @include respond-max-width(xs) {
                padding: 0.5em 0;
                margin: 0.5em 0;
                text-align: center;
                width: 100%; } } }
    .description-section {
        margin: auto 2em;
        .description {
            font-family: $robotoCondensed;
            font-size: 1em;
            color: $bodyGrayColor; } } }


