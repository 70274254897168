@import '../../../../styles/variables-mixins.sass';

.game-info-view {
    margin-bottom: 4em;
    .game-info-view-container {
        overflow: hidden;

        /* Set Max width styles adjustment */
        @media (min-width: 1500px) {
            width: 1500px;
            margin: auto;

            .image-gallery,
            .video-gallery {
                width: 100%;
                .title {
                    display: inline-block;
                    position: relative; } }

            .games {
                .games-body {
                    .content-container {
                        margin-left: 0; } } } } } }


