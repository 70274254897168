@import '../../../../../styles/variables-mixins.sass';

$margin-offset: 0.5em;
.collapse-item {
    .bg-container {
        padding: 5em 0;
        @include respond-max-width(sm) {
            padding: 4em 0; }
        @include respond-max-width(xs) {
            padding: 1em 0; }
        .open-btn {
            background: none;
            border: none;
            outline: none;
            position: relative;
            left: 1em;
            .collapse-title {
                @include font-base;
                display: inline-block;
                font-weight: 500;
                text-transform: uppercase;
                color: $white;
                font-size: 2em;
                @include respond-max-width(xs) {
                    font-size: 1.2em; }
                .custom-icon {
                    font-size: 1em;
                    transition: transform linear 0.2s;
                    display: inline-block;
                    transform: rotate(0deg);
                    vertical-align: bottom; }
                .custom-icon.active {
                    display: inline-block;
                    transform: rotate(90deg); } }
            .collapse-title.text {
                display: inline-block;
                text-align: left;
                margin: 0 0 0 0.5em;
                @include respond-max-width(xs) {
                    display: inline;
                    width: 80%;
                    margin: auto; } } } }

    .bg-container.active {
        background-position-x: 100%; }

    .display-container {
        display: block;
        height: auto;
        overflow: hidden;
        transition: height ease-in-out 0.3s;
        @include respond-max-width(sm) {
            padding: 0 15px; }
        .display-title-section {
            @include respond-max-width(xs) {
                text-align: center; }
            .title {
                @include font-base;
                font-weight: 600;
                font-size: 1.5em;
                text-transform: uppercase;
                color: $white;
                @include respond-max-width(xs) {
                    font-size: 1.2em;
                    padding: 0.5em;
                    display: inline-block;
                    background: $black; }
                .highlight {
                    padding: 0.1em 0.5em 0.1em 1em;
                    line-height: 0.9em;
                    display: inline-block;
                    background: $black;
                    @include respond-max-width(xs) {
                        padding: 0;
                        background: none; } } } }

        .description-section {
            margin: 3em 5em;
            .description {
                @include font-base;
                font-weight: 500;
                color: $black;
                img {
                    width: 100%; } } } }

    .display-container.hidden {
        display: none; } }
