@import '../../../../../styles/variables-mixins.sass';

$active-color: #497e8a;

.list-dropdown-container {
    .list-dropdown {
        border-bottom: 1.5px solid $borderGrayColor;
        margin: auto 2em;
        clear: both;
        @include respond-max-width(xs) {
            margin: auto 1em; }
        .toggle-btn {
            background: none;
            outline: none;
            border: none;
            width: 100%; }
        .title-section {
            float: left;
            @include respond-max-width(xs) {
                margin-top: 0.5em; }
            .drop-down-title {
                @include title-font-base;
                font-size: 2em;
                font-weight: 600;
                margin: 0;
                margin: 0.5em auto;
                color: $black;
                @include respond-max-width(xs) {
                    font-size: 1em; } }

            .drop-down-title.active {
                color: $active-color; } }

        .toggle-section {
            margin: 1.5em auto;
            float: right;
            @include respond-max-width(xs) {
                margin: 0.5em auto; }

            .icon {
                font-size: 2em;
                @include respond-max-width(xs) {
                    font-size: 1.5em; } }

            .icon.icon-chevron-down {
                transition: transform ease-in 0.25s;
                font-size: 2em;
                display: block;
                @include respond-max-width(xs) {
                    position: relative; } }

            .icon.icon-chevron-down.active {
                transform: rotate(180deg);
                color: $active-color; }

            .icon.icon-plus-square {} } }


    .content {
        transition: height ease-in-out 0.5s;
        clear: both;
        padding: 1.5em;
        background: $game-manual-bg;
        border-top: 1.5px solid $borderGrayColor;
        overflow: hidden;
        @include respond-max-width(xs) {
            padding: 0 5px; }
        .description {
            @include font-base;
            font-size: 1.1em;
            margin: auto 2em;
            padding: 1em 0;
            font-weight: 500;
            @include respond-max-width(xs) {
                width: 275px;
                margin: auto;
                p {
                    font-size: 1em; } }
            table {
                @include respond-max-width(xs) {
                    width: 100% !important; // Force content to be all 100%
                    td {
                        font-size: 0.85em; } } }
            img {
                width: 100%;
                margin-bottom: 1em;
                @include respond-max-width(xs) {
                    width: 80%;
                    height: auto; }
                @include respond-min-width(lg) {
                    width: 500px;
                    height: auto;
                    display: block; } } } }

    .list-dropdown.active {
        border: none; } }

.list-dropdown-container.sub-list {
    .toggle-btn {
        /* Force Width on Apple phyiscal mobile device to avoid device bug */
        margin: auto;
        font-size: 0.8em;
        @media screen and (max-width: 320px) {
            width: 240px !important; }
        @media (min-width: 325px) and (max-width: 375px) {
            width: 275px !important; } }

    .content {
        @include respond-max-width(xs) {
            width: auto !important;
            margin: auto;
            .description {
                ul, ol {
                    padding-left: 0.2em; } } }
        .sub-content {
            @include font-base;
            font-weight: 500; } }

    .list-dropdown {
        border: none;
        .title-section {
            .drop-down-title {
                font-size: 1.25em;
                @include respond-max-width(xs) {
                    font-size: 0.9em; } } } } }


