@import '../../../../../styles/variables-mixins.sass';
@import '../../../../../styles/apple-download.sass';

.games {
    width: 100%;
    background-size: contain;
    background-position: bottom right;
    @include respond-max-width(md) {
        background-size: 60% auto; }
    @include respond-max-width(xs) {
        background: none !important; }
    .games-body {
        width: 100%;
        max-width: 1500px;
        height: 650px;
        padding: 1em;
        margin: 4em auto 0;
        @include respond-max-width(xs) {
            height: auto;
            margin: 1em auto 0; }
        .content-container {
            width: 500px;
            margin-top: 4em;
            @include respond-min-width(lg) {
                width: 450px; }
            @include respond-max-width(sm) {
                margin-top: 0;
                width: 100%; }
            .title-section {
                display: inline-block;
                .title {
                    @include title-font-base;
                    font-size: 2.5em;
                    color: $black; } }
            .description-section {
                .description {
                    font-family: $robotoCondensed;
                    font-size: 1em;
                    color: $bodyGrayColor; } }

            .phrase-section {
                @include respond-max-width(xs) {
                    text-align: left; }
                .phrase {
                    margin: 0;
                    font-family: $robotoCondensed;
                    font-size: 1.8em;
                    font-weight: 600;
                    color: $phraseTextColor;
                    text-transform: uppercase; } }

            .download-section {
                margin: 1.5em auto;
                .apple-download-logo {
                    @include apple-download-icon--black;
                    height: 3.5em;
                    width: auto;
                    background-repeat: no-repeat;
                    background-size: contain; } }
            .device-compatibility-section {
                font-family: $robotoCondensed;
                font-size: 1em;
                color: $devicesBaseTextColor;
                @include respond-max-width(sm) {
                    max-width: 20em; }
                @include respond-max-width(xs) {
                    max-width: 100%;
                    text-align: left;
                    padding: 2em 0; }
                .device-text {
                    color: $devicesTextColor;
                    font-style: italic; }
                .bold {
                    font-weight: 600; } } } } }
