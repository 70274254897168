@import '../../../../../styles/variables-mixins.sass';

.news-section {
    background: $white;
    padding: 3em 5em;
    @include respond-max-width(xs) {
        padding: 1.5em 0.5em;
        .activityGridRow .activity-item {
            border-right: none; } }

    .news-title {
        text-align: center;
        .text {
            @include font-base;
            margin: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 4em;
            color: $white;
            @include respond-max-width(sm) {
                font-size: 3.5em; }
            @include respond-max-width(xs) {
                font-size: 2em; }
            .highlight {
                background: $darkGray;
                line-height: 0.9em;
                display: inline-block;
                padding: 0 0.2em; } } }


    .news-wrapper {
        margin: 2em auto;
        text-align: center; } }
