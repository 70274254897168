@import '../../../../../styles/variables-mixins.sass';
@import '../../../../../styles/apple-download.sass';
@import '../../../../../styles/bg-icons.sass';

.game-info-section {
    max-width: 1400px;
    margin: auto;

    .custom-pag.custom-dots.game-info-dots {
        li {
            .dots {
                width: 10px;
                height: 10px; } } }

    .game-select {
        width: 100%;
        padding: 0.75em 1em;
        font-size: 1.25em;
        font-family: $robotoCondensed;
        font-weight: 600;
        background: $borderGrayColor;
        border-radius: 0;
        border: none;
        margin: auto auto 1.5em auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
            display: none; }
        @include chevron-down;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        background-position-y: 50%;
        background-position-x: 120%; }

    .links {
        margin-top: 0;
        text-align: center;
        padding: 0;
        @include respond-max-width(sm) {
            padding: 0; }
        .link-container {
            list-style-type: none;
            display: inline-block;
            padding: 3.5em 0 0 0;
            text-align: left;
            border-left: 2.5px solid $light-gray;
            @include respond-max-width(sm) {
                padding: 0;
                margin: 1em auto;
                text-align: center; }

            .link-button {
                @include font-base;
                text-align: left;
                line-height: 50px;
                border: none;
                background: none;
                font-size: 2.25em;
                padding-right: 25px;
                text-transform: uppercase;
                font-weight: 700;
                color: #fff;
                &:focus {
                    outline: none; }
                @include respond-max-width(lg) {
                    font-size: 2em;
                    padding-right: 0; }

                @include respond-min-width(lg) {
                    font-size: 2.75em; }

                @include respond-max-width(sm) {
                    line-height: 1;
                    font-size: 1.5em; }
                .text {
                    margin: 3px 50px 4px 0;
                    background: $darkGray;
                    padding: 0 5px; }

                .text.text-part-one,
                .text.text-part-two {
                    line-height: 1em; }

                .text.text-part-two {
                    margin-top: 2px;
                    float: left;
                    padding: 0 5px;
                    @include respond-max-width(sm) {
                        display: inline-block;
                        float: none; } } } }

        .link-container.active {
            border-color: $gold;
            .link-button {
                color: #fff;
                .text {
                    background: $gold; } } } }

    .info-bg {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom right;
        @include respond-max-width(md) {
            background-position: -50% 100%;
            background-size: auto 100%; }
        @include respond-max-width(sm) {
            background-position: 10% 0;
            background-size: cover; }
        @include respond-max-width(xs) {
            background-position: 50% 100%;
            background-size: auto 100%; } }
    &.infinity-blade-ii {
        .info-bg {
            @include respond-max-width(sm) {
                background-position: 25% 0; } } }

    .info-container {
        width: 85%;
        margin: auto;
        min-height: 42em;
        @include respond-max-width(md) {
            width: 100%;
            padding: 0 2em; }
        .img-section {
            .active-img {
                width: 375px;
                max-width: 100%;
                height: auto; } }

        .content-wrapper {
            @include respond-max-width(sm) {
                padding-left: 1em; }
            @include respond-max-width(lg) {
                padding-left: 2em; }
            .description-section {
                width: 55%;
                @include respond-max-width(xs) {
                    width: auto;
                    background: $darkGray;
                    color: $white;
                    font-size: 0.9em;
                    padding: 1em; }

                .description {
                    @include font-base;
                    text-align: left;
                    font-weight: 500;
                    font-size: 1.4em;
                    line-height: 1.5em; } }

            .learn-more-section {
                padding: 1em 0;
                @include respond-max-width(xs) {
                    padding: 1.25em 0; }
                .link {
                    @include font-base;
                    margin: 1.5em auto;
                    font-size: 1.5em;
                    text-transform: uppercase;
                    color: $gold-text;
                    font-weight: 700;
                    text-decoration: none;
                    .icon-chevron-right {
                        font-size: 0.8em; } } }

            .download-link-section {
                margin: 1em auto 0 auto;
                padding-bottom: 3em;
                @include respond-max-width(xs) {
                    margin-left: 1em; }
                .download-img {
                    @include apple-download-icon--black;
                    width: 200px;
                    height: 66px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include respond-max-width(xs) {
                        margin: auto; } } } } } }


/* Carousel section */
.game-info-section {
    .info-container.more-links {
        padding: 2em 30% 2em 0;
        @include respond-max-width(xs) {
            padding: 8em 0; }

        .description-section {
            width: 90%;
            margin: auto;
            .description {
                @include font-base;
                font-weight: 500;
                font-size: 1.1em;
                line-height: 1.5em;
                margin-bottom: 2em;
                @include respond-max-width(xs) {
                    background: $darkGray;
                    color: $white;
                    padding: 1em; }
                @include respond-max-width(lg) {
                    font-size: 1.5em; } } }

        .links {
            .carousel-container.game-info-carousel {
                .slick-slider {
                    .slick-arrow.slick-prev {
                        top: 5em; }
                    .slick-arrow.slick-next {
                        top: 4em; } }
                @include respond-min-width(lg) {
                    width: 800px; }
                @include respond-max-width(lg) {
                    width: 100%;
                    .slick-prev {
                        .slick-control {
                             display: block !important; } } }
                @include respond-max-width(xs) {
                    margin: 2em auto; }

                .showcase-container {
                    .slide-image-container {
                        text-align: center;
                        .link {
                            text-decoration: none;
                            .slide-image {
                                margin: auto;
                                width: 200px;
                                height: auto;
                                @include respond-max-width(xs) {
                                    width: 150px; } } } }

                    .slide-description {
                        @include font-base;
                        font-size: 1em;
                        color: $gold-text;
                        .showcase-title {
                            font-weight: 700; } } }

                .slick-arrow {
                    position: absolute;
                    display: inline-block;
                    top: 45%;
                    z-index: 99;
                    height: auto;
                    cursor: pointer;
                    overflow: hidden;
                    opacity: 0.7;
                    transition: opacity 0.3s ease-in-out;

                    .slick-control {
                      display: inline; }

                    i {
                      position: relative;
                      display: inline-block;
                      width: 5em;
                      height: 5em;
                      border-radius: 50%;
                      vertical-align: middle;
                      margin: 0;
                      transition: opacity 0.3s ease-in-out;
                      font-size: 100%; }

                    i.next:before {
                      border-top: 5px solid $gold;
                      border-right: 5px solid $gold; }

                    i.icon-arrow:before {
                      border-left: 5px solid $gold;
                      border-bottom: 5px solid $gold; }

                    i:before {
                      content: '';
                      position: absolute;
                      top: 1.1em;
                      left: 0;
                      right: 0;
                      margin: 0 auto;
                      display: inline-block;
                      width: 1.5em;
                      height: 1.5em;
                      transform: rotate(45deg); }

                    &.slick-next {
                      right: 0;
                      top: 2em;
                      i {
                        transform: rotate(-180deg); }
                      i:before {
                        right: 0.1em; } }

                    &.slick-prev {
                      left: 0;
                      top: 2em;
                      // Set height to fix width expansion
                      width: 70px;
                      height: 70px;
                      i {
                        transform: rotate(0); }
                      i:before {
                        left: 0.1em; } }

                    &.slick-prev:hover,
                    &.slick-next:hover {
                      opacity: 1; } } } } } }
