@import '../../../../../styles/variables-mixins.sass';

.buy-now-section.cta-section {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 48em;
    @include respond-min-width(lg) {
        background-position: center; }
    @include respond-max-width(lg) {
        min-height: 38em; }
    @include respond-max-width(sm) {
        min-height: 36em; }

    .buy-now-btn {
        @include font-base;
        padding: 15px 75px;
        font-weight: 700;
        font-size: 1.75em;
        width: auto;
        border-radius: 0;
        @include respond-max-width(xs) {
            font-size: 1.25em;
            margin-top: 4em; } }

    .section-wrapper {
        @include respond-min-width(lg) {
            padding: 2em 0; }

        .logo-section {
            padding: 2em 0;
            text-align: center;
            @include respond-min-width(lg) {
                padding-top: 6em; }
            .logo.ib-logo {
                width: 400px;
                height: auto;
                @include respond-min-width(lg) {
                    width: 500px; }
                @include respond-max-width(sm) {
                    width: 325px; }
                @include respond-max-width(xs) {
                    width: 285px; } } }

        .title-section {
            text-align: center;
            margin: 1em auto;
            width: 75%;
            @include respond-max-width(sm) {
                width: 100%; }
            .title.title-bg {
              margin: 0;
              font-size: 3em;
              line-height: 2.2em;
              @include respond-max-width(sm) {
                font-size: 2em; }
              @include respond-max-width(xs) {
                font-size: 1.5em; }
              .text {
                  @include font-base;
                  font-size: 3.75em;
                  text-transform: uppercase;
                  font-weight: 700;
                  padding: 0 10px;
                  color: $white;
                  background-color: $darkGray;
                  line-height: 0.85em;
                  display: inline-block; } } }

        .cta-section {
            text-align: center;
            padding: 2em 0; } } }
