@import '../../../../styles/variables-mixins.sass';

@mixin btn-blog {
    position: relative;
    font-size: 1.75em;
    padding: 0 2em;
    //color: $text-color
    margin-top: 0.5em;
    background: transparent !important;
    span {
        position: relative;
        z-index: 1; }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2em;
        //background-color: $accent-color
        transition: background-color 0.3s ease-in-out;
        //box-shadow: 0 4px 12px -4px rgba($invert-text-color, 0.5)
        transform-origin: 50% 50% 20px;
        animation: jelly 6s ease-in-out infinite; }
    &:hover {
        &:after {
 } }            //background-color: darken($accent-color, 10%) !important
    @include respond-max-width(xs) {
        //background: $accent-color !important
        padding: 0.25em 1.25em 0 !important;
        &:after {
            display: none; } } }

//Fortnite Custom Styling
.blog-view-container .blog-view {
    a {
 }        //color: darken($accent-color, 20%)
    .headline-banner,
    .headline {
        width: 65%;
        h2 {
            //font-family: $font_0
            font-size: 3em;
            margin-bottom: 0;
            @include respond-max-width(sm) {
                font-size: 2em !important; } }
        .category-info {
            font-size: 1.5em; } }
    .headline-banner {
        max-width: 1170px;
        max-height: 45vw;
        border: 1px solid $lightGray; }

    .category-filter {
        border: 1px solid $lightGray; } }

.article-view {
    min-height: 80vh;
    .blog-header {
        max-width: 1170px;
        margin: 0 auto;
        border: 1px solid $lightGray;
        height: 480px; }
    //background-color: $white
    .blog-header-info {
        hr {
 }            //border-color: lighten($darkGray, 60%) !important
        .blog-header-title {
            margin-top: 0.2em !important; } }
    .textile-preview {
        ul {
            margin-left: 1.5em;
            li {
                list-style: disc; } }
        &.selected.selected {
 } }            //border: 1px solid $accent-color
    .cmsContainer {
        h1 {
 }            //font-family: $font_0 !important
        h3, h4, h5, h6 {
 }            //font-family: $font_4 !important
        .blog-header-info {
 }            //font-family: $font_4

        .cms {
            ul {
                font-size: 15px; }
            a {
                font-size: 1em;
                font-weight: 500;
                //color: $accent-color !important
                &:hover, &:active, &:focus {
 } } } } }                    //color: darken($accent-color, 10%) !important
.blog-wrapper, .ReactModalPortal {
    background: #efefef;
    padding-top: 2em;
    .blog-load-indicator {
        .loading-icon {
            //background: url('../../../../images/loading/FN-spin.gif') center no-repeat
            height: 3em; } }

    .load-more {
        margin: 0 auto;
        padding: 1em 0;
        .btn-load {
            @include btn-custom;
            padding: 20px; } }

    .blog-container {
        .comment-rating-box .pagination .comment-load-more {
            @include btn;
            @include btn-blog; }
        .comment-rating-box .text-area-box {
            .sso-sign-in .not-login span {
                font-weight: 500;
 }                //color: $white !important
            .not-login {
                @include btn;
                @include btn-blog;
                .sso-sign-in {
                    font-weight: 500;
                    span {
 } } }                        //color: $white !important
            .textile-preview.selected {
 } }                //border: 1px solid $accent-color !important
        table {
            tr:first-child:first-child {
                //background-color: $accent-color
 } } }                //color: $white

    .comment-edit-box, .comment-rating-box {
        .loading-svg {
            //background: url('../../../../images/loading/FN-spin.gif') center no-repeat
            background-size: 3em;
            height: 5em; }
        .textile-container {
            .textile-head {
                li {
                    box-sizing: content-box;
                    padding: 0.2em 1.2em;
                    border-radius: 0; } } } }

    .blog-view-container .headline-banner.headline-btn {
        background-color: darken(gray, 20%);
        .btn-news {
            text-shadow: none;
            @include btn-custom;
            border: none;
            padding: 10px;
            margin-top: 1em; } }

    .btn, .btn-primary {
 }        //+btn-blog
    .blog-container .blog-article .blog-content {
        min-height: 52vh; }
    .blog-container .blog-article .prev-link-wrap {
        .prev-link, .next-link {
            span {
                font-size: 1.125em;
                vertical-align: sub; }

            i {
                font-size: 1.4em; } } }
    .loading-icon {
        background-size: 3em;
        height: 5em; }

    .cw-close {
 } }        //color: $accent-color

