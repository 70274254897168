@import '../../../../../styles/variables-mixins.sass';

.recent-post-wrapper {
    .recentPostContainer {
        .content-toss {
            text-align: left;
            .activityGridRow-date {
                @include title-font-base;
                font-weight: 600;
                font-size: 1em;
                color: $gold-text; }
            .byline {
                display: none; }
            .short {
                span {
                    white-space: normal !important; } } } }

    .container.activityGridRow.content-grid-row {
        @include respond-min-width(sm) {
            width: auto; } }

    .content-toss {
        @include respond-min-width(sm) {
            .activity-item.content-toss-item {
                border-right: none; }
            .activity-item.content-toss-item:nth-child(3) {
                float: right; } } } }
