
.custom-pag.custom-dots {
    margin: 0;
    padding: 2em 0;
    text-align: center;
    li {
        display: inline-block;
        list-style-type: none;
        .dots {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            border: 2px solid #000;
            margin: 0.5em; } }

    .slick-active {
        .dots {
            background: #000; } } }


