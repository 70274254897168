@import '../../../../styles/variables-mixins.sass';

.errorPages {
    width: 100%;
    height: 100vh;
    background: url('../../../../images/ErrorBG.png') center no-repeat;
    background-size: cover !important;
    font-family: $OpenSans;
    font-weight: 400;
    font-size: 16px;
    .content {
        max-width: 80em;
        margin: 0 auto;
        height: 100%; }
    .errorHeader {
        @include vertical-align;
        max-width: 34em;
        padding: 2em;
        text-align: center;
        margin: 0 auto;
        @include respond-max-width(sm) {
            max-width: 90vw; }
        h1 {
            font-family: $BebasNeue;
            font-weight: 600;
            font-size: 18em;
            margin: 0;
            @include respond-max-width(sm) {
                font-size: 11em; } }
        h3 {
            font-weight: 500;
            margin: 0 0 2em; }
        p {
            display: block;
            color: $darkGray;
            font-size: 1em;
            margin: 0 0 2em; }

        .error-detail {
            font-size: 0.9em;
            margin-bottom: 2em; }

        .btn {
            @include btn; } } }
