@import '../../../../../styles/variables-mixins.sass';

.image-gallery {
    padding: 3em 0;
    .title {
        @include title-font-base;
        color: $white;
        background: $black;
        padding: 0.1em 0.5em 0.1em 1.5em;
        display: inline-block;
        @include respond-max-width(xs) {
            font-size: 1.75em;
            padding: 0.5em 0;
            margin: 0.5em 0;
            text-align: center;
            width: 100%; } }

    .gallery-carousel {
        margin: 2em auto;
        .slick-prev,
        .slick-next {
            top: -4em;
            width: 2em;
            height: 2em;
            @include respond-max-width(xs) {
                top: -5.3em; }
            .slick-control {
                display: none; } }
        .slick-next {
            right: 0; }

        .slick-prev {
            left: unset;
            right: 1.7em; }
        .slick-prev:before,
        .slick-next:before {
            background: $gameInfoCarouselArrowColor;
            padding: 5px; }

        .slick-prev:before {
            @include previous-arrow; }

        .slick-next:before {
            @include next-arrow; }

        .slick-slide {
            overflow: hidden;
            margin-right: 1em; }

        .slick-list {
            overflow: visible;
            @include respond-max-width(xs) {
                padding: 0 !important; } }

        .gallery-dots {
            .dots {
                border-color: $light-gray;
                width: 10px;
                height: 10px; }
            .slick-active {
                .dots {
                  background: #4d94a1;
                  border-color: #4d94a1; } } }


        .gallery {
            box-shadow: 0px 12px 27px -13px rgba(0, 0, 0, 0.65);
            width: 100%;
            height: 100%;
            @media (min-width: 1400px) {
                height: 450px;
                width: auto; }
            @include respond-max-width(lg) {
                height: 375px;
                width: auto; }
            @include respond-max-width(xs) {
                height: auto;
                width: 90vw; }
            h3 {
                text-align: center;
                @include vertical-align; } } } }
