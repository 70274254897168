@import '../../../../../styles/variables-mixins.sass';

.game-manual {
    .main-title-section {
        .title {
            @include title-font-base;
            color: #fff;
            @include respond-max-width(xs) {
                font-size: 1.75em;
                padding: 0.5em 0;
                margin: 0.5em 0;
                text-align: center;
                width: 100%;
                background: $black; }
            .highlight {
                padding: 0.1em 0.5em 0.1em 1em;
                background: $black;
                @include respond-max-width(xs) {
                    background: none;
                    padding: 0; } } } }


    .info-section {
        .divider {
            border-bottom: 2.5px solid $borderGrayColor;
            margin: auto 2em;
            @include respond-max-width(xs) {
                margin: auto 1em; } }

        #suppliesTable {
            img {
                max-width: 4em;
                margin-right: 1em; } }

        .ingredients {
            .description {
                table {
                    text-align: center;
                    img {
                        max-width: 8em;
                        margin-right: 2em; } } } } } }

