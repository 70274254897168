@import '../../../../../styles/variables-mixins.sass';

.mobile-table {
    .content {
        .description {
            @include respond-max-width(md) {
                table {
                    img {
                        width: auto !important;
                        height: auto; }
                    td {
                        padding: 1em 0 0 1em;
                        font-size: 0.85em; } } }

            @include respond-max-width(xs) {
                table {
                    width: 100% !important;
                    img {
                        width: 100% !important; } } } } } }

.mobile-table.item-elements,
.mobile-table.item-stat-modifier {
    .content {
        .description {
            table {
                @include respond-max-width(xs) {
                    width: 100% !important;
                    td {
                        padding: 1em 0;
                        font-size: 0.85em; } }
                @include respond-min-width(xs) {
                    img {
                        width: auto !important;
                        height: auto !important;
                        margin: auto;
                        display: block;
                        padding: 1em; } } } } } }

.mobile-table.ingredients {
    .content {
        @include respond-max-width(xs) {
            .description {
                table {
                    img {
                        width: auto !important; } } } } } }
.mobile-table.gems {
    .content {
        @include respond-max-width(xs) {
            width: 250px !important;
            .description {
                width: auto;
                table {
                    img {
                        width: 50px !important;
                        margin: auto;
                        display: block; } } } }

        @include respond-min-width(xs) {
                table {
                    img {
                        width: auto !important;
                        height: auto !important;
                        margin: auto;
                        display: block;
                        padding: 1em; } } } } }


.mobile-table.supplies {
    .content {
        .description {
            table {
                img {
                    width: auto;
                    height: 100px;
                    @include respond-max-width(md) {
                        width: 65px !important;
                        margin: auto;
                        display: block; } } } } } }

.list-dropdown-container.moves {
    .content {
        .description {
            img {
                width: auto !important;
                height: auto;
                display: inline-block; }

            @include respond-max-width(xs) {
                img {
                    display: inline-block; } } } } }

.list-dropdown-container.exploration {
    .content {
        @include respond-max-width(xs) {
            width: 100% !important;
            .description {
                margin: auto;
                ol {
                    padding-left: 1.5em; } }
            .list-dropdown-container.sub-list {
                width: 100% !important; } } } }

