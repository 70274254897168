.fade-in-enter {
  opacity: 0.01; }

.fade-in-enter.fade-in-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-in-exit {
  opacity: 1; }

.fade-in-exit.fade-in-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in; }

.fade-in-appear {
  opacity: 0.01; }

.fade-in-appear.fade-in-appear-active {
  opacity: 1;
  transition: opacity 400ms ease-in; }

