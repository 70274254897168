@import '../../../../../styles/variables-mixins.sass';

.community {
    margin: auto;
    @include respond-max-width(xs) {
        margin: auto; }

    .community-title-section {
        text-align: center;
        margin: auto;
        .text {
            @include font-base;
            margin: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 2.5em;
            color: $white;
            @include respond-max-width(xs) {
                font-size: 2em; }
            .highlight {
                background: $darkGray; } } }

    .connect-section {
        background: $darkOffset;
        .connect-container {
            width: 65%;
            margin: auto;
            padding: 3em 0;
            @include respond-max-width(xs) {
                width: 100%;
                padding: 2em 0; }
            .connect-title {
                @include font-base;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 4em;
                color: $darkOffset;
                text-align: center;
                @include respond-max-width(sm) {
                    font-size: 3.5em; }
                @include respond-max-width(xs) {
                    font-size: 2em; }

                .highlight {
                    font-size: inherit;
                    background: $white;
                    padding: 0 0.5em;
                    line-height: 0.9em;
                    display: inline-block; } }

            .social-share-list {
                text-align: center;
                margin: 3em auto;
                padding: 0;
                @include respond-max-width(xs) {
                    padding-top: 1em;
                    margin: 1em auto; }
                .social-share {
                    color: $white;
                    font-size: 3.5em;
                    padding: 0 1em;
                    display: inline-block;
                    &:hover {
                        color: $gold-text; }
                    @include respond-max-width(sm) {
                        font-size: 2em;
                        padding: 0 0.5em; } } } } } }
