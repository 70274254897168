@import '../../../../../styles/variables-mixins.sass';

.testimonial-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    min-height: 40em;
    @include respond-max-width(sm) {
        min-height: auto; }

    .testimonial-carousel {
        @include respond-max-width(xs) {
            padding-bottom: 2em; } }

    .custom-pag.custom-dots.testimonial-dots {
        li {
            .dots {
                width: 10px;
                height: 10px; } } }

    .testimonial-container {
        width: 100%;
        @include respond-max-width(xs) {
            margin: 1em auto;
            padding: 0; }

        .rating-section {
            margin: auto;
            position: absolute;
            margin-left: -5em;
            text-align: center;
            margin-top: -9.6em;
            z-index: -1;
            @include respond-max-width(xs) {
                margin-left: 0;
                margin-top: -6em; }
            .rating-number {
                @include font-base;
                margin: 0;
                color: $darkGold;
                font-weight: 700;
                font-size: 10.5em;
                @include respond-max-width(xs) {
                    font-size: 5.5em; } } }

        .text-section {
            background: $darkGray;
            padding: 0.5em;
            width: 65%;
            max-width: 500px;
            margin: auto;
            margin-top: 18em;
            @include respond-max-width(sm) {
                width: auto;
                margin-top: 12em;
                margin-bottom: 8em; }
            @include respond-max-width(xs) {
                padding: 1em;
                margin: 12em 2em 6em; }

            .text {
                @include font-base;
                margin: 0;
                font-weight: 500;
                font-size: 1.5em;
                color: #fff;
                padding: 0 2em 1em 0;
                @include respond-max-width(xs) {
                    line-height: 1.5em;
                    font-size: 1em; } }

            .company {
                @include font-base;
                margin: 0;
                color: $gold-text;
                text-transform: uppercase;
                font-size: 1.2em;
                font-weight: 600;
                text-align: right;
                @include respond-max-width(xs) {
                    font-size: 1.25em; } } } } }

